import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  getAllWorkload: [],
  getworkdetail: [],
  currentPage: 1,
  loading: false,
  isLoading: false,
  error: null,
};
export const fetchWorkload = createAsyncThunk(
  "workload/fetchWorkload",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/workload/`,
        {
          params: {
            p_months: 3,
            u_months: 2,
            page_offset: 1,
          },
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.data;

    } catch (error) {
      throw error.response
        ? error.response.data
        : error.message || error.toString();
    }
  }
);
export const getdetail = createAsyncThunk(
  "workload/getdetail",
  async ({data}) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/workload/day_details/?date=${data}`,
     
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
     
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const workloadSlice = createSlice({
  name: "workload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkload.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWorkload.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.getAllWorkload = action.payload;
      })
      .addCase(fetchWorkload.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload ||
          "An error occurred while fetching the workload data.";
      })


      .addCase(getdetail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getdetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.getworkdetail = action.payload;
      })
      .addCase(getdetail.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload ||
          [];
      });
  },
});

export default workloadSlice.reducer;
