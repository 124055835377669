import * as React from "react";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  MenuItem,
  Typography,
  Paper,
  FormControl,
  Select,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useState, useEffect } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsFillImageFill } from "react-icons/bs";
import { PiTrashFill } from "react-icons/pi";
import CustomPagination from "Components/CustomPagination";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import cross from "../../Assets/Images/cross.svg";
import "../../Assets/StyleCss/styles.css";
import Loader from "Loader";
import { VehiclesType, getsearchVehicles } from "redux/slices/VehicleSlice";
import {
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import { TextValidator } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { deleteVehicles, getAllVehicles } from "redux/slices/VehicleSlice";
import { createUser } from "redux/slices/VehicleSlice";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import UploadMultipleFiles from "./UploadMultipleFiles";
import axios from "axios";
import Deletebox from "Assets/Dialog box/Deletebox";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { setAlert } from "redux/slices/alertSlice";
import SearchIcon from '@mui/icons-material/Search';
const AutoPlaySwipeableViews = SwipeableViews;
export default function Vehicles() {
  function PaginationComponent(props) {
    const { total,search, getData,vehicle, currentPage, ...rest } = props;
    
    return (
      <GridPagination
        ActionsComponent={(paginationProps) => (
          <CustomPagination
            currentPage={currentPage}
            total={total}
            search={search}
            vehicle={vehicle}
            getData={getData}
            {...paginationProps}
          />
        )}
        {...rest}
      />
    );
  }
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle.getAllVehicles);
  const rowcounts=useSelector((state)=>state.vehicle.rowcounts)

  const [typeselect, settypeselect] = useState(false);
  // const [imagereq, setimagereq] = useState(false);
 
 var vehiclearr = []
 if (Array.isArray(vehicle)) {
  vehiclearr = vehicle
}
  ValidatorForm.addValidationRule("ismax20", (value) => {
    // Change the regular expression according to your desired phone number format
    value = (value + "").trim();
    if (value.length <= 20 && value.length >= 2) {
      return true;
    } else {
      return false;
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const vehicleType = useSelector((state) => state.vehicle.VehiclesType) ?? [];

  let vehicleId =
    vehicleType !== undefined
      ? vehicleType?.map((item) => {
        return { name: item.name, id: item.id };
      })
      : [];

  const [openvehicle, setOpenvehicle] = React.useState(false);
  const [opendelete, setOpendelete] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [formdata, setFromdata] = useState({
    vehicle_type: "na",
    number: "",
    status: "",
    images: [],
  });
  const [openimage, setOpenimage] = useState(false);
  const handleCloseImage = () => {
    setOpenimage(false);
  };
  const [Formdata11, setFormData] = useState({ files: [] });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagestate, setimagestate] = useState([]);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
 const [addLoader,setAddLoader]=useState(false)
 const [search,setSearch]=useState("");
const searchdata=(value)=>{
  dispatch(getsearchVehicles({search:value,pagenumber:1}));
}
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleFormChange = (e) => {
    setFromdata({ ...formdata, [e.target.name]: e.target.value });
  };
  const handleChangeselectbox = (event) => {
    if (
      event.target.value + "" === "" ||
      event.target.value + "" === undefined
    ) {
      settypeselect(true);
    } else {
      settypeselect(false);
    }
    setFromdata({ ...formdata, vehicle_type: event.target.value });
  };
  const getTotalPages = useSelector((state) => state.vehicle.totalPages);
  const currentPage = useSelector((state) => state.vehicle.currentPage);
  const handleCloseDelete = () => {
    setOpendelete(false);
  };

  const handleCloseAdduser = () => {
    setOpenvehicle(false);
    setFromdata({
      vehicle_type: "",
      number: "",
      status: "",
      images: [],
    });
    setSelectedFiles([]);
  };
  const handleOpenAdduser = () => {
    setOpenvehicle(true);
  };

  const handleDelete = (vehicleId) => {
    setOpendelete(false);
    dispatch(deleteVehicles(vehicleId)).then((res) => {
      dispatch(
        setAlert({
          open: true,
          message: "Data deleted successfully",
          severity: "success", // or "error", "warning", "info"
          duration: 6000,
        })
      );
    }
    ).catch((err) => { 

      dispatch(
        setAlert({
          open: true,
          message: "something went wrong",
          severity: "error", // or "error", "warning", "info"
          duration: 6000,
        })
      );
    });
    setDeleteUserId(null);
  };

  const handleAdduser = () => {
    setAddLoader(true)
    if (
      formdata.vehicle_type + "" === "" || formdata.vehicle_type==="na"||
      formdata.vehicle_type + "" === undefined
    ) {
      settypeselect(true);
      setAddLoader(false)
      return;
    } else {
      settypeselect(false);
    }
    
    if (
      !formdata.number ||
      !formdata.vehicle_type
    ) {
     
      dispatch(
        setAlert({
          open: true,
          message: "Form data is incomplete",
          severity: "error", // or "error", "warning", "info"
          duration: 6000,
        })
      );
      setAddLoader(false)
      return;
    }

    var formData1 = new FormData();
    formData1.append("number", formdata.number);
    formData1.append("vehicle_type", formdata.vehicle_type);

    for (let i = 0; i < selectedFiles.length; i++) {
      formData1.append("images", selectedFiles[i]);
    }

    for (var [key, value] of formData1.entries()) {
    }

    dispatch(createUser(formData1))
      .unwrap()
      .then((res) => {
        if (res.status === 400) {
          // toast.error(res.data?.number[0] ?? "Something went wrong!!!");
          dispatch(
            setAlert({
              open: true,
              message: res.data?.number[0] ?? "Something went wrong!!!",
              severity: "error", // or "error", "warning", "info"
              duration: 6000,
            })
          );                                                
          setAddLoader(false)
        } else {
          // toast.success("Vehicle Added Successfully");
          dispatch(
            setAlert({
              open: true,
              message: "Vehicle Added Successfully",
              severity: "success", // or "error", "warning", "info"
              duration: 6000,
            })
          );
          setFromdata({
            vehicle_type: "",
            number: "",
            status: "",
            images: [],
          });
          setAddLoader(false)
          setSelectedFiles([]);
          handleCloseAdduser();
        }
      })
      .catch((error) => {setAddLoader(false) 
      });
  };
  //  making image action to showing image of every vehicle in popup by using vehicle id
  const handleImage = (vehicleId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/vehicles/${vehicleId}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setimagestate(res.data.images);
        //  closing pop after post
        setActiveStep(0)
        setOpenimage(true);

      
      })
      .catch((err) => { });
  };

  useEffect(() => {
    setIsLoading(true);
   dispatch(getAllVehicles(1));
  
    dispatch(VehiclesType());
    
    if(vehicle && vehicle.length >= 0 ){
      setIsLoading(false);
      
    }  
  }, [dispatch]);
  const columns = [
    {
      field: "s.no",
      type: "string",
      flex: 1,
      minWidth: 165,
      renderHeader: () => (
        <strong>
          <Typography
            variant="overline"
            fontWeight="bold"
            sx={{ textDecodation: "none", paddingLeft: "20px" }}
          >
            Serial Number
          </Typography>
        </strong>
      ),
      renderCell: (params, index) => (
        <Typography sx={{ textDecodation: "none", paddingLeft: "30px" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "number",
      type: "string",
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <strong>
          <Typography variant="overline" fontWeight="bold">
            Car Number
          </Typography>
        </strong>
      ),
      renderCell: (params) => (
        <Typography sx={{ textDecodation: "none" }}>{params.value}</Typography>
      ),
    },
    {
      field: "vehicle_type",
      type: "string",
      flex: 1,
      minWidth: 155,
      renderHeader: () => (
        <strong>
          <Typography variant="overline" fontWeight="bold">
            Vehicle Type
          </Typography>
        </strong>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "7px",
          }}
        >
          <Typography sx={{ textDecodation: "none" }}>
            {vehicleId?.find((item) => item.id === params.value)?.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      type: "string",
      flex: 1,
      minWidth: 135,
      renderHeader: () => (
        <strong>
          <Typography variant="overline" fontWeight="bold">
            Status
          </Typography>
        </strong>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "7px",
          }}
        >
          <Typography sx={{ textDecodation: "none" }}>
          

            {(params.value+"").charAt(0).toUpperCase() + (params.value+"").slice(1)
            }
          </Typography>
        </Box>
      ),
    },
    {
      field: "created_at",
      type: "string",
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <strong>
          <Typography variant="overline" fontWeight="bold">
            Created on
          </Typography>
        </strong>
      ),
      renderCell: (params) => (
        <Typography sx={{ textDecodation: "none" }}>
          {new Date(params.value)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            .replace(/,/g, "")}
        </Typography>
      ),
    },
    {
      field: "updated_at",
      type: "string",
      flex: 1,
      minWidth: 180,
      renderHeader: () => (
        <strong>
          <Typography variant="overline" fontWeight="bold">
            Updated on
          </Typography>
        </strong>
      ),
      renderCell: (params) => (
        <Typography sx={{ textDecodation: "none" }}>
          {new Date(params.value)
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            .replace(/,/g, "")}
        </Typography>
      ),
    },
    {
      field: "action",
      type: "string",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <strong>
          <Typography variant="overline" fontWeight="bold">
            Action
          </Typography>
        </strong>
      ),
      renderCell: (params) => (
        <div className="actions">
          <div
            className="actionitems"
            onClick={() => {
              setActiveStep(0);
              handleImage(params.row.id);
            

              setimagestate([]);
            }}
          >
            <BsFillImageFill />
          </div>
          <div
            className="actionitems"
            onClick={() => {
              setDeleteUserId(params.row.id);
              setOpendelete(true);
            }}
          >
            <PiTrashFill />
          </div>
        </div>
      ),
    },
  ];



  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <div className="sectiontitle">
        <div className="title">
          <label className="titletag">Vehicles</label>
        </div>
      
        <div className="addbtn" >
       
        <div className="search-field">
        <TextField
        
            id="outlined-basic"
            label="Search Car Number"
            variant="outlined"
            size="small"
            autoComplete="off"
            value={search}
            onChange={(e)=>{setSearch(e.target.value);searchdata(e.target.value)}}
            className="searchbar"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className="searchicon" />
                </InputAdornment>
              ),
            }}
          />
        
        </div>
         
            
          <button className="addbuttoncontrol" onClick={handleOpenAdduser}>
            {" "}
            <AiFillPlusCircle /> Add Vehicle
          </button>
        </div>
      </div>

      <>
        <div className="section-inner" style={{ width: "100%" }}>
          {isLoading ? (
            <Loader />
          ) : (
            <DataGrid
              autoHeight
              className="custom-table full_height_table"
              pagination slots={{
                pagination: (props) => (
                  <PaginationComponent
                    getData={getAllVehicles}
                    search={search}
                    vehicle={vehicle}
                    currentPage={currentPage}
                    total={getTotalPages}
                    pagesize={vehiclearr?.length}
                    rows={vehiclearr}
                    {...props}
                  />
                ),
              }}
              rows={vehiclearr?.map((item, index) => {
                return {
                  ...item,
                  "s.no":
                    currentPage > 1
                      ? index + (currentPage * 10 - 9)
                      : index + 1,
                };
              })}
              columns={columns}
              pageSize={10}
              paginationMode="server"
              
              rowCount={rowcounts}
              initialState={{
                pagination: {
                  paginationModel: { page: 1, pageSize: 10, type: "server",rowCount:rowcounts
                  },
                },
              }}
              pageSizeOptions={[10]}
            />
          )}
        </div>
      </>

      <div>
        <Dialog
          className="popup-section"
          open={openvehicle}
          onClose={handleCloseAdduser}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="popup-body">
            <div className="popup-title">Add Vehicle Detail</div>
            <div className="popup-title-cross">
              <IconButton onClick={handleCloseAdduser} autoFocus>
                <img src={cross} alt="cross" className="cross" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ValidatorForm
                onSubmit={() => {
                  setAddLoader(true)
                  handleAdduser(formdata.id);
                }}
              >
                <div className="row-popup">
                  <div className="model-input-full">
                    <label className="popup-label">Car Number:</label>
                    <TextValidator
                      validators={["required", "ismax20"]}
                      errorMessages={[
                        "Car number is required",
                        "Car number should be less than 20 characters long (minimum 2 characters)",
                      ]}
                      className="popup-input1"
                      type="text"
                      name="number"
                      value={formdata.number}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="row-popup">
                  <div className="model-input-full">
                    <label className="popup-label">Vehicle Type:</label>

                    <FormControl className="outselect" fullWidth error={typeselect}>
                      <Select
                        IconComponent={(props) => (
                          <i
                            {...props}
                            className={` ${props.className}`}
                            style={{ marginRight: "15px" }}
                          >
                            <svg
                              width="19"
                              height="11"
                              viewBox="0 0 19 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.69531 1.5L9.69531 9.5L17.6953 1.5"
                                stroke="#4E445C"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </i>
                        )}
                        labelId="demo-simple-select-label"
                        validators={["required"]}
                        errorMessages={["Vehicle type is required"]}
                        style={{ padding: "0px 17px" }}
                        id="demo-simple-select"
                        className="innerselect popup-input1 selectbox"
                        value={formdata.vehicle_type}
                        name="vehicle_type"
                        placeholder="Status"
                        displayEmpty
                        onChange={handleChangeselectbox}
                      >
                        {" "}
                        <MenuItem value="na" disabled>
                          Select Vehicle
                        </MenuItem>
                        {vehicleType?.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {typeselect && (
                        <FormHelperText>
                          Vehicle Type is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
                <div className="row-popup">
                  <div className="model-input-full">
                    <label className="popup-label">Upload Images:</label>
                    <UploadMultipleFiles
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      setFormData={setFormData}
                      FormData={Formdata11}
                      selectedFilesError={false}
                    />
                  </div>
                </div>

                <div className="popup-button">
                  <label>
                    <button
                      className="btncontrol"
                      type="submit"
                      style={{ padding: "12px 30px" }}
                    >
                      Add Vehicle 
                      {
                        addLoader?<CircularProgress sx={{width:'20px !important',height:'20px !important',color:'#fff'}}/>:null
                      }
                    </button>
                  </label>
                  <label>
                    <button
                      onClick={handleCloseAdduser}
                      className="btncontrol1"
                    >
                      Cancel
                    </button>
                  </label>
                </div>
              </ValidatorForm>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* Delete Popup */}
      <Deletebox
        open={opendelete}
        onClose={handleCloseDelete}
        title={"Are you sure you want to delete this vehicle?"}
        onConfirm={() => handleDelete(deleteUserId)}
      />
      {/* Image Popup */}
      <div>
        <Dialog
          sx={{ padding: "0px !important" }}
          className="imagepopup-section"
          open={openimage}
          onClose={handleCloseImage}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="popup-body">
            <div className="popup-title">Vehicle Image{openimage}</div>
            <div className="popup-title-cross">
              <IconButton onClick={handleCloseImage} autoFocus>
                <img src={cross} alt="cross" className="cross" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {imagestate.length === 0 && (
              <Typography
                sx={{ fontSize: "21px", fontWeight: "500", padding: "5px" }}
                className="noimage"
              >
                <ImageNotSupportedIcon sx={{ width: '150px', height: '150px',color:'gray' }} />
              </Typography>
            )}
            <Box sx={{ maxWidth: 800, flexGrow: 1 }}>
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {imagestate.map((step, index) => (
                  <div key={index} className="modal-image-container">
                    {/* {Math.abs(activeStep - index) <= 2 ? ( */}
                      <Box
                        component="img"
                        sx={{
                          display: "block",
                          width: "100%",
                          height: 'auto',
                          maxHeight:"400px",
                          objectFit:"contain",
                          overflow: "hidden",
                        }}
                        src={step.image}
                      />
                    {/* ) : null} */}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
             { imagestate.length>1? <MobileStepper
                steps={imagestate.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                  activeStep < imagestate.length - 1 ? (
                    <Button size="small"

                      onClick={handleNext}>
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  ) : (
                    <Button size="small" disabled   >
                      Next
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  )
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}

                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />:null}
            </Box>
          </DialogContent>

          <div className="popup-button"></div>
        </Dialog>
      </div>
    </div>
  );
}
