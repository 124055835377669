import React from "react";
import { Box, Typography, Chip, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { CloudUpload } from "@mui/icons-material";
import { setAlert } from "redux/slices/alertSlice";
import { useDispatch } from "react-redux";

const FileUploader = ({
  setFormData,
  selectedFiles,
  setSelectedFiles,
  selectedFilesError,
}) => {
  const dispatch = useDispatch();
  const acceptedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
  // const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  const handleFilesChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => {
      
          return (
              (acceptedImageTypes.includes(file.type) )
             
          );
      
  });

  if (validFiles.length !== files.length) {
      // Show toast error message for invalid file types
      const invalidFiles = files.filter(
          (file) => !validFiles.includes(file)
      );
      const invalidFileNames = invalidFiles
          .map((file) => file.name)
          .join(", ");
     
          // toast.error(
          //     `Invalid file type for: ${invalidFileNames}, Please select a valid image with extensions: .png, .jpg, .jpeg`
          // );
          dispatch(
            setAlert({
              open: true,
              message: `Invalid file type for: ${invalidFileNames}, Please select a valid image with extensions: .png, .jpg, .jpeg`,
              severity: "error", // or "error", "warning", "info"
              duration: 6000,
            })
          );
      
  }
    // const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...validFiles]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      files: [...prevFormData.files, ...validFiles],
    }));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      <label htmlFor="file-input">
        <Box
          sx={{
            border: `2px dashed #bbbcc1`,
            borderRadius: "8px",
            margin: "12px 0px 12px 0px",
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            cursor: "pointer",
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <>
            <CloudUpload fontSize="large" sx={{ color: "#1d47a9" }} />
            <Typography sx={{ color: "#4e445c" }}>
              Upload multiple images or drag and drop
            </Typography>
          </>
          <input
            type="file"
            id="file-input"
            multiple
            style={{ display: "none" }}
            onChange={handleFilesChange}
            accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
          />
        </Box>
        {selectedFilesError && (
          <Typography color="red">File is required</Typography>
        )}
      </label>
      <Typography></Typography>
      {selectedFiles.length > 0 && (
        <Stack direction="row" flexWrap="wrap" spacing={1} row={1}>
          {selectedFiles.map((file, index) => (
            <>
              

              <Chip
                size="medium"
                className="chipset"
                icon={
                  <img
                    src={URL.createObjectURL(file)}
                    width={"20px"}
                    height={"20px"}
                    alt=""
                  />
                }
                label={file.name}
                key={index}
                onDelete={() => handleDelete(index)}
              />
            </>
          ))}
        </Stack>
      )}
    </>
  );
};

export default FileUploader;
