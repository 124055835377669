import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { calculatePageNumbers } from "utils/calculatePageNumbers";
const initialState = {
  getAllVehicles: [],
  currentPage: 1,
};
export const getAllVehicles = createAsyncThunk(
  "getAllVehicles",
  async (pagenumber = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/vehicles/?page=${pagenumber}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      response.data.currentPage = pagenumber;

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);

export const getsearchVehicles = createAsyncThunk(
  "getsearchVehicles",
  async ({search = "",pagenumber=1}, { dispatch }) => {
   
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/vehicles/?number=${search}&?page=${pagenumber}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      response.data.currentPage = pagenumber;
      if (search === ""|| !search || search === null || search === undefined) {
        dispatch(getAllVehicles(1))
        return response.data;
      }
      else {

        return response.data;
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  }
);
export const getAllVehicleoffsite = createAsyncThunk(
  "getAllVehicleoffsite",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/vehicles/`,
        {
          params: {
            status: "offsite"
          },
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      const vehicleoffsite = response.data.results

      return vehicleoffsite;
    } catch (error) {

      return [];
    }
  }
);
export const VehiclesType = createAsyncThunk("VehiclesType", async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/vehicle_types/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data.results;
  } catch (error) {

    return [];
  }
});
export const deleteVehicles = createAsyncThunk(
  "deleteVehicles",
  async (vehicleId, { dispatch }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/vehicles/${vehicleId}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      dispatch(getAllVehicles(1));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return message;
    }
  }
);
export const createUser = createAsyncThunk(
  "postAllVehicles",
  async (userData, { dispatch }) => {
    for (var [key, value] of userData.entries()) {
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/vehicles/`,
        userData, // Use formData instead of userData
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data", // Set the content type for file upload
          },
        }
      );

      dispatch(getAllVehicles(1));
      return response.data;
    } catch (error) {
      const message = error.response && error.response;
      return message;
    }
  }
);

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.getvehicle = null;
      state.getAllVehicleoffsite = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVehicles.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllVehicles.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload || "Error occurred";
    });
    builder.addCase(getAllVehicles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.getAllVehicles = action.payload.results;
      state.rowcounts = action.payload.count;
      state.totalPages = calculatePageNumbers(action.payload.count);
      state.currentPage = action.payload.currentPage;
    });





    builder.addCase(getsearchVehicles.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getsearchVehicles.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload || "Error occurred";
    });
    builder.addCase(getsearchVehicles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.getAllVehicles = action.payload.results;
      state.rowcounts = action.payload.count;
      state.totalPages = calculatePageNumbers(action.payload.count);
      state.currentPage = action.payload.currentPage;
    });
    builder.addCase(deleteVehicles.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteVehicles.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload || "Error occurred";
    });
    builder.addCase(VehiclesType.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(VehiclesType.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload || "Error occurred";
    });
    builder.addCase(VehiclesType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.VehiclesType = action.payload;
    });
    builder.addCase(getAllVehicleoffsite.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllVehicleoffsite.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload || "Error occurred";
    });
    builder.addCase(getAllVehicleoffsite.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.getAllVehicleoffsite = action.payload;
    });
    builder.addCase(createUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload || "Error occurred";
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.message = action.payload;
    });
  },
});

export default vehicleSlice.reducer;
