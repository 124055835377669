import React, { useEffect, useState } from "react";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import dayjs from "dayjs";
import Loader from "Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkload } from "redux/slices/WorkloadSlice";
import { FaCircle } from "react-icons/fa";
import Drawer from '@mui/material/Drawer';
import WorkloadSidebar from "./WorkloadSidebar";


dayjs.locale("en");
const initialValue = dayjs(new Date());

const ServerDay = (props) => {
  const {
    day,
    outsideCurrentMonth,
    scheduledDates,
    highDate,
    lowDate,
    mediumDate,
    open,
    setopen,
    setdata,
    ...other
  } = props;
  const formatDate = dayjs(props.day).format("YYYY-MM-DD");
  const low = lowDate.includes(formatDate);
  const medium = mediumDate.includes(formatDate);
  const high = highDate.includes(formatDate);

  return (
    <PickersDay
    onClick={(e)=>{e.preventDefault();setopen(true);setdata({date:formatDate})}}
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={{
        backgroundColor: high
          ? "#D1435B"
          : medium
          ? "#FF9400"
          : low
          ? "#00A698"
          : "#F0F0F0",
        color: high ? "white" : medium ? "white" : low ? "white" : "black",
        fontWeight: "bold",
      }}
    >
      {dayjs(formatDate).format("DD")}
    </PickersDay>
  );
};

const Workload = () => {
  const [lowDate, setLowDate] = useState([]);
  const [mediumDate, setMediumDate] = useState([]);
  const [highDate, setHighDate] = useState([]);
const [open, setopen] = useState(false);
const [data, setdata] = useState({});
  const dispatch = useDispatch();
  const workloadData = useSelector((state) => state.workload.getAllWorkload);
  const loading = useSelector((state) => state.workload.loading);
  useEffect(() => {
    dispatch(fetchWorkload());
  }, [dispatch]);

  useEffect(() => {
    if (workloadData) {
      const lowDateArray = workloadData
        .filter((item) => item.sessions_count > 0 && item.sessions_count <= 5)
        .map((item) => {
          const lowFormattedDate = dayjs(item.scheduled_at).format(
            "YYYY-MM-DD"
          );
          return lowFormattedDate;
        });

      const mediumDateArray = workloadData
        .filter((item) => item.sessions_count >= 5 && item.sessions_count <= 10)
        .map((item) => {
          const mediumFormattedDate = dayjs(item.scheduled_at).format(
            "YYYY-MM-DD"
          );
          return mediumFormattedDate;
        });

      const highDateArray = workloadData
        .filter((item) => item.sessions_count > 10)
        .map((item) => {
          const highFormattedDate = dayjs(item.scheduled_at).format(
            "YYYY-MM-DD"
          );
          return highFormattedDate;
        });

      setLowDate(lowDateArray);
      setMediumDate(mediumDateArray);
      setHighDate(highDateArray);

    }
  }, [workloadData]);

  return (
    <>
      <div>
        <div className="workload-main">
          <div className="workload-heading">
            <h1>Work Load</h1>
          </div>
        </div>

            {loading ? (
              <div className="worload-loder-height">
          <Loader />
        </div>

        ) : ( <div className="section-inner-workload">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangeCalendar
                className="customCalendar"
                defaultValue={initialValue}
                loading={loading}
                slots={{
                  day: (props) => {
                    return (
                      <ServerDay
                        {...props}
                       open={open}
                       setopen={setopen}
                       setdata={setdata}
                        highDate={highDate}
                        lowDate={lowDate}
                        mediumDate={mediumDate}
                      />
                    );
                  },
                }}
              />
            </LocalizationProvider>
       </div>
    )}
             <div className="parkingmap-footer">
        <ul>
          <li>
            <span>
              <FaCircle style={{ color: "#00A698" }} />
             Low
            </span>
          </li>
          <li>
            <span>
              <FaCircle style={{ color: "#FF9400" }} />
              Medium
            </span>
          </li>
          <li>
            <span>
              <FaCircle style={{ color: "#D1435B" }} />
              High
            </span>
          </li>
          <li>
            <span>
              <FaCircle style={{ color: "#D9D9D9" }} />
             Holiday
            </span>
          </li>

        </ul>
      </div>
      </div>
      <Drawer
                className='drawww'
                anchor='right'
                sx={{ width: "100%", maxWidth: "450px" }}
                open={open}
                onClose={() => {
                    setopen(false);
                }}>
        <WorkloadSidebar data={data} setopen={setopen} open={open} />
      </Drawer>
    </>
  );
};

export default Workload;
