
import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import cross from "Assets/Images/cross.svg";
import polishingicon from "Assets/Images/polishingicon.svg";
import pickbyicon from "Assets/Images/pickbyicon.svg";
import Repairingicon from "Assets/Images/damageicon.svg";
import washingicon from "Assets/Images/washingicon.svg";
import arrivingicon from "Assets/Images/arriving.svg";
import { FaCaravan, FaMap, FaUserCog } from "react-icons/fa";
import axios from 'axios';
import { getdetail } from 'redux/slices/WorkloadSlice';
import { useDispatch, useSelector } from 'react-redux';
export default function WorkloadSidebar({data,open,setopen}) {
const dispatch=useDispatch()
const workside=useSelector((state)=>state.workload.getworkdetail)

  const date=data.date
  useEffect(() => {
    dispatch(getdetail({data:date}))
  }, [])
 //function that take value as 15:51:08 and return 3:51 PM
 const timeformat = (time) => {
  // Assuming time is in the format HH:mm:ss
  const [hours, minutes] = time.split(':');
  
  // Creating a new Date object with a dummy date (January 1, 1970)
  const dummyDate = new Date(1970, 0, 1, hours, minutes);

  // Formatting the time in 12-hour format
  const formattedTime = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  }).format(dummyDate);

  return formattedTime;
};
  //2023-12-02 is getting from data.date get date month and year so that i can print date like 2 December 2023
  const dateformat = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "numeric" }).format(d);
    return `${da} ${mo} ${ye}`;
  };
  
  // Output: 2 December 2023
  const iconData=(value)=>{
    switch(value){
      case 'Polishing':
        return polishingicon
      case 'Pick By':
        return pickbyicon
      case 'Repairing':
        return Repairingicon
      case 'Washing':
        return washingicon
      case 'Arriving':
        return arrivingicon
      default:
        return polishingicon
    }
  }
  return (
    <div className='workload-sidebar-section parking-info'>
      <div className="popup-title-cross">
        <Button
          autoFocus
        onClick={() => {
          setopen(false);
        }}
        >
          <img
            src={cross}
            alt="cross"
            className="cross"
            style={{ width: "15px" }}
          />
        </Button>
      </div>
      <div className='workload-title'>
        

        {dateformat(date)}</div>
        {
       (Array.isArray(workside)) &&  workside.map((item)=>{
            return(
              <div className='workload-content' >
        <div className='work-heading'>
          <div className='work-heading-title'>{item?.work}</div>
          <div className='work-heading-value'><img src={iconData(item?.work)} width='30px' height='25px' alt='polishing' /></div>
        </div>
        <div className='workload-content-user' style={{marginBottom:'15px'}}>
        <div className='work-heading-title1'>
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
  <g clip-path="url(#clip0_1276_7167)">
    <path d="M26.9709 15.3573C26.9662 15.2297 26.696 15.0007 26.5757 15.0175C26.4211 15.0385 26.2599 15.241 26.1651 15.4035C26.1017 15.512 26.1467 15.6864 26.1467 15.8308C26.1461 16.4062 26.1461 16.9816 26.1461 17.5809C25.1863 17.5809 24.2744 17.5809 23.3662 17.5809C23.4141 17.2699 23.4941 16.9876 23.4947 16.7047C23.5042 13.9081 23.4965 11.1115 23.5018 8.31488C23.503 7.58187 23.2708 6.95074 22.7594 6.4335C20.8238 4.4766 18.8869 2.52149 16.9453 0.571187C16.5513 0.175012 16.0577 0.00179781 15.5032 0.00179781C10.9587 0.00179781 6.41428 -0.00599383 1.86984 0.00719202C0.690778 0.0107882 0.00585353 0.727619 0.00466854 1.91554C-0.00125641 6.79851 -0.0018489 11.6821 0.00466854 16.565C0.00644602 17.7739 0.718032 18.4656 1.91783 18.468C2.76095 18.4692 3.60467 18.468 4.44957 18.468C4.33462 20.7078 5.2127 21.9604 6.91731 21.9988C7.63363 22.015 8.26583 21.7645 8.78426 21.2508C9.56398 20.4782 9.69611 19.5318 9.45615 18.468C9.6339 18.468 9.76128 18.468 9.88867 18.468C13.8311 18.468 17.773 18.4686 21.7155 18.4686C23.2779 18.4686 24.8409 18.4632 26.4033 18.4722C26.696 18.474 26.9685 18.4039 26.9792 18.094C27.0106 17.1824 27.0058 16.2689 26.9709 15.3573ZM11.3166 10.5763C11.2846 11.6024 10.5594 12.291 9.54147 12.303C8.82573 12.3114 8.10941 12.3048 7.39367 12.3042C6.67794 12.3036 5.96161 12.3126 5.24588 12.3024C4.23034 12.288 3.5229 11.582 3.50513 10.5517C3.48972 9.66883 3.48854 8.78597 3.50631 7.90312C3.52587 6.92977 4.18294 6.20634 5.14219 6.18237C6.64476 6.14521 8.1491 6.14701 9.65167 6.17997C10.6032 6.20095 11.2816 6.91358 11.3148 7.88454C11.3462 8.78118 11.345 9.68022 11.3166 10.5763ZM18.2689 17.5917C16.8238 17.5917 15.4078 17.5917 13.9597 17.5917C13.9597 14.0831 13.9597 10.5852 13.9597 7.05323C15.3811 7.05323 16.806 7.05323 18.2689 7.05323C18.2689 10.5535 18.2689 14.0483 18.2689 17.5917Z" fill="url(#paint0_linear_1276_7167)"/>
    <path d="M26.9709 15.3573C26.9662 15.2297 26.696 15.0007 26.5757 15.0175C26.4211 15.0385 26.2599 15.241 26.1651 15.4035C26.1017 15.512 26.1467 15.6864 26.1467 15.8308C26.1461 16.4062 26.1461 16.9816 26.1461 17.5809C25.1863 17.5809 24.2744 17.5809 23.3662 17.5809C23.4141 17.2699 23.4941 16.9876 23.4947 16.7047C23.5042 13.9081 23.4965 11.1115 23.5018 8.31488C23.503 7.58187 23.2708 6.95074 22.7594 6.4335C20.8238 4.4766 18.8869 2.52149 16.9453 0.571187C16.5513 0.175012 16.0577 0.00179781 15.5032 0.00179781C10.9587 0.00179781 6.41428 -0.00599383 1.86984 0.00719202C0.690778 0.0107882 0.00585353 0.727619 0.00466854 1.91554C-0.00125641 6.79851 -0.0018489 11.6821 0.00466854 16.565C0.00644602 17.7739 0.718032 18.4656 1.91783 18.468C2.76095 18.4692 3.60467 18.468 4.44957 18.468C4.33462 20.7078 5.2127 21.9604 6.91731 21.9988C7.63363 22.015 8.26583 21.7645 8.78426 21.2508C9.56398 20.4782 9.69611 19.5318 9.45615 18.468C9.6339 18.468 9.76128 18.468 9.88867 18.468C13.8311 18.468 17.773 18.4686 21.7155 18.4686C23.2779 18.4686 24.8409 18.4632 26.4033 18.4722C26.696 18.474 26.9685 18.4039 26.9792 18.094C27.0106 17.1824 27.0058 16.2689 26.9709 15.3573ZM11.3166 10.5763C11.2846 11.6024 10.5594 12.291 9.54147 12.303C8.82573 12.3114 8.10941 12.3048 7.39367 12.3042C6.67794 12.3036 5.96161 12.3126 5.24588 12.3024C4.23034 12.288 3.5229 11.582 3.50513 10.5517C3.48972 9.66883 3.48854 8.78597 3.50631 7.90312C3.52587 6.92977 4.18294 6.20634 5.14219 6.18237C6.64476 6.14521 8.1491 6.14701 9.65167 6.17997C10.6032 6.20095 11.2816 6.91358 11.3148 7.88454C11.3462 8.78118 11.345 9.68022 11.3166 10.5763ZM18.2689 17.5917C16.8238 17.5917 15.4078 17.5917 13.9597 17.5917C13.9597 14.0831 13.9597 10.5852 13.9597 7.05323C15.3811 7.05323 16.806 7.05323 18.2689 7.05323C18.2689 10.5535 18.2689 14.0483 18.2689 17.5917Z" fill="black" fill-opacity="0.2"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_1276_7167" x1="-1.98304e-07" y1="10.6662" x2="27.0002" y2="10.6662" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1D91DE"/>
      <stop offset="1" stop-color="#1D47A9"/>
    </linearGradient>
    <clipPath id="clip0_1276_7167">
      <rect width="27" height="22" fill="white"/>
    </clipPath>
  </defs>
</svg>
          
          {/* <FaCaravan style={{width:'27px',height:'22px'}} /> */}
          </div>
          <div className='work-heading-value1'>{item?.vehicle}</div>
          
        </div>
        <div className='workload-content-user' style={{marginBottom:'4px'}}>
        <div className='work-heading-title1'>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
  <path d="M25.6237 21.5506C25.6629 21.2672 25.678 20.9809 25.6687 20.6949C25.6683 20.3776 25.6381 20.061 25.5787 19.7492L26.8396 18.9836L25.2184 16.0564L23.9124 16.822C23.4599 16.4328 22.9413 16.1277 22.3812 15.9213V14.3901H19.0487V15.9663C18.4882 16.1719 17.9695 16.477 17.5175 16.867L16.2115 16.0564L14.5453 18.9836L15.8062 19.7042C15.6905 20.3142 15.6905 20.9406 15.8062 21.5506L14.4102 22.3612L16.0764 25.2884L17.4275 24.4778C17.8893 24.8886 18.4239 25.2094 19.0037 25.4235V26.9997H22.3362V25.4235C22.9315 25.2135 23.4816 24.8926 23.9574 24.4778L25.3535 25.2884L27.0198 22.3612L25.6237 21.5506ZM20.715 22.4963C20.3587 22.4963 20.0104 22.3907 19.7142 22.1927C19.4179 21.9948 19.187 21.7134 19.0507 21.3843C18.9144 21.0551 18.8787 20.6929 18.9482 20.3435C19.0177 19.9941 19.1893 19.6731 19.4412 19.4212C19.6931 19.1692 20.0141 18.9977 20.3635 18.9282C20.713 18.8587 21.0752 18.8943 21.4043 19.0307C21.7335 19.167 22.0148 19.3979 22.2127 19.6941C22.4107 19.9904 22.5163 20.3387 22.5163 20.6949C22.5163 21.1727 22.3265 21.6309 21.9887 21.9687C21.6509 22.3065 21.1927 22.4963 20.715 22.4963Z" fill="url(#paint0_linear_1276_7163)"/>
  <path d="M25.6237 21.5506C25.6629 21.2672 25.678 20.9809 25.6687 20.6949C25.6683 20.3776 25.6381 20.061 25.5787 19.7492L26.8396 18.9836L25.2184 16.0564L23.9124 16.822C23.4599 16.4328 22.9413 16.1277 22.3812 15.9213V14.3901H19.0487V15.9663C18.4882 16.1719 17.9695 16.477 17.5175 16.867L16.2115 16.0564L14.5453 18.9836L15.8062 19.7042C15.6905 20.3142 15.6905 20.9406 15.8062 21.5506L14.4102 22.3612L16.0764 25.2884L17.4275 24.4778C17.8893 24.8886 18.4239 25.2094 19.0037 25.4235V26.9997H22.3362V25.4235C22.9315 25.2135 23.4816 24.8926 23.9574 24.4778L25.3535 25.2884L27.0198 22.3612L25.6237 21.5506ZM20.715 22.4963C20.3587 22.4963 20.0104 22.3907 19.7142 22.1927C19.4179 21.9948 19.187 21.7134 19.0507 21.3843C18.9144 21.0551 18.8787 20.6929 18.9482 20.3435C19.0177 19.9941 19.1893 19.6731 19.4412 19.4212C19.6931 19.1692 20.0141 18.9977 20.3635 18.9282C20.713 18.8587 21.0752 18.8943 21.4043 19.0307C21.7335 19.167 22.0148 19.3979 22.2127 19.6941C22.4107 19.9904 22.5163 20.3387 22.5163 20.6949C22.5163 21.1727 22.3265 21.6309 21.9887 21.9687C21.6509 22.3065 21.1927 22.4963 20.715 22.4963Z" fill="black" fill-opacity="0.2"/>
  <path d="M13.5103 20.8303L13.8255 20.6502L13.6454 20.5601C13.2366 20.3241 12.9369 19.9371 12.8105 19.4823C12.6842 19.0275 12.7414 18.5413 12.9699 18.1283L14.0057 16.3269C13.7649 15.9864 13.6532 15.5714 13.6904 15.156V14.3904C14.6098 13.5425 15.2663 12.4483 15.5819 11.238C16.7077 11.238 16.9329 8.94129 16.9329 8.62605C16.9329 8.31081 16.9779 6.91474 15.8521 6.91474C18.2839 1.10532 11.889 -1.95701 7.11541 1.37553C5.13391 1.37553 4.9988 4.03255 5.71935 6.91474C4.59349 6.91474 4.63853 8.26577 4.63853 8.62605C4.63853 8.98632 4.90873 11.238 5.98956 11.238C6.30747 12.4473 6.9636 13.5409 7.881 14.3904V15.111C7.881 15.8315 7.881 16.9124 4.23322 17.6329C0.585445 18.3534 0 21.5959 0 21.5959H12.7897C12.9484 21.2748 13.1993 21.0082 13.5103 20.8303Z" fill="url(#paint1_linear_1276_7163)"/>
  <path d="M13.5103 20.8303L13.8255 20.6502L13.6454 20.5601C13.2366 20.3241 12.9369 19.9371 12.8105 19.4823C12.6842 19.0275 12.7414 18.5413 12.9699 18.1283L14.0057 16.3269C13.7649 15.9864 13.6532 15.5714 13.6904 15.156V14.3904C14.6098 13.5425 15.2663 12.4483 15.5819 11.238C16.7077 11.238 16.9329 8.94129 16.9329 8.62605C16.9329 8.31081 16.9779 6.91474 15.8521 6.91474C18.2839 1.10532 11.889 -1.95701 7.11541 1.37553C5.13391 1.37553 4.9988 4.03255 5.71935 6.91474C4.59349 6.91474 4.63853 8.26577 4.63853 8.62605C4.63853 8.98632 4.90873 11.238 5.98956 11.238C6.30747 12.4473 6.9636 13.5409 7.881 14.3904V15.111C7.881 15.8315 7.881 16.9124 4.23322 17.6329C0.585445 18.3534 0 21.5959 0 21.5959H12.7897C12.9484 21.2748 13.1993 21.0082 13.5103 20.8303Z" fill="black" fill-opacity="0.2"/>
  <defs>
    <linearGradient id="paint0_linear_1276_7163" x1="14.4102" y1="20.5039" x2="27.0198" y2="20.5039" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1D91DE"/>
      <stop offset="1" stop-color="#1D47A9"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1276_7163" x1="-1.24366e-07" y1="10.4707" x2="16.9331" y2="10.4707" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1D91DE"/>
      <stop offset="1" stop-color="#1D47A9"/>
    </linearGradient>
  </defs>
</svg>
          
          {/* <FaCaravan style={{width:'27px',height:'22px'}} /> */}
          </div>
          <div className='work-heading-value1'>{item?.completed_by}</div>
       
        </div>
        <div className='work-time'>
          {timeformat(item?.scheduled_at_time)}
        
        </div>
      </div>
            )
        })
      }
      
    </div>
  )
}
